
.cc-frame-container {
  margin: -20px -20px 0px -20px;
  margin-bottom: 25px;
}

.carat-frame {
  width: 100%;
}

.stores__swiper .swiper-container {
    width: 100%;
    height: 150px;
  }

.stores__swiper .swiper-slide {
  text-align: center;
  font-size: 15px;
  background-color:bisque;
  border: 1px solid blue;
  border-radius: 50px;
  width: 100px;
  height: 120px;  
}

.related__swiper .swiper-container {
    width: 100%;
    height: 150px;
  }

.related__swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background:green;
  height: 120px;
  border: 1px solid red;
  border-radius: 20px;
  width: 90px !important;
}
.counter__styles{
    justify-content: flex-start;
    display: flex;
    vertical-align: bottom;
    padding: 20px 20px; 
    margin-top: 20px;
}

.counter__label {
 padding: 0px 20px;
 margin-top: 5px; 
}

.counter{
  text-align: center;
    width: 45px;
    margin: 0px;
    background-color: lightgray;
}

.add__to__cart__btn1{
    flex: 3;
    margin-left: 14px;
    margin-right: 14px;
    font-size: 130px;
    padding: 0 !important;
    white-space: nowrap;
}

.counter__btn{
    flex: 2;
    border: none; 
    outline: none;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid blue;

}


.my-modal {
  width: 95vw;    /* Occupy the 90% of the screen width */
  max-width: 95vw;
  height: 95vh;
  max-height: 95vh;
} 

.my-images{
  height: 25vh;
}

.back__btn {
  font-size: 25px;
  border: none;
  background: none;
  margin-top: -5px;
  padding: 0;
  margin-left: -5px;
}

.modal-header .close {
  font-size: 35px;
  color: black;
}

.checkout__btn {
  float: right;
}

/* .modified-img {
  height: 100%; 
  /* width: auto; 
} 
*/


