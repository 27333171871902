.label-and-input-container {
display: flex; 
flex-direction: column;


}

.label-style {
    font-family: Gotham; 
    font-weight: 400; 
    font-style: normal;
    line-height: 11.50px;
    font-size: 12px;
    margin-bottom: 0px;
    color: #999999;
}

.input-style{
    font-family: Gotham; 
    font-weight: bold; 
    font-style: normal;
    line-height: 15.30px;
    font-size: 16px;
    border: none;
    border-bottom: 0.25px solid #F5F5F5;
    padding-bottom: 9.5px;
    width: 100%;

}