.primary-button-style {
    /* button */
    background: linear-gradient(92.8deg, #8fc4e8 -6.07%, #afa7e1 113.21%);
    border-radius: 3px;
    padding: 8px 12px;
    border: none;
    /* button text */
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height, or 200% */
    color: #ffffff;
}

.secondary-button-style {
    /* button */
    background: lightgrey;
    border-radius: 3px;
    padding: 8px 12px;
    border: none;
    /* button text */
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height, or 200% */
    color: rgb(26, 36, 45);
}

.splashscreen-started-button-style {
    /* background: url('/images/splashScreen-button.svg'); */
    position: relative;
    width: 315px;
    height: 50px;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
    
}

.lets-get-started-text {
    font-family: Gotham;
    color:blue; 
    position: relative; 
    top: -35px;
    text-align: center;
    color: white;
}

.started-button-text {
    font-size: 20px;
}

.medium-sized-card-button {
    @extend .primary-button-style;
    width: 144px; 

}
