input.radio {
    width: auto;
}

label.logo__container {
    margin: 0;
    padding: 0px;
    width: 30%;
}

.heading-and-button-style {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.heading-and-button-style h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 21px;
}

.heading-and-button-style button p {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
}

.payment__logo__format {
    width: 80%;
}

.checkout__img__format img {
    border: 1px solid black !important;
    width: 80%;
    height: 80%;
    border-radius: 1vw;
}
.charges-summary {
    display: flex; 
    justify-content: space-between;
    margin-top: 0px;
    font-family: Gotham;
    font-size: 16px;
    padding: 0 20px;
}

.charges-summary p {
    margin: 0 0px;
}
.add-new-card {
    border: none;
    background: none;
    font-size: 16px;
    color: blue;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly; 
    position: relative; 
    top: 5px;
}

.plus-icon {
    position: relative;
    right: 10px;
    top: 3px;
    width: 13px;
    height: 13px;
}
