.footer__nav__container{
    max-width: 1920px; 
    width: 100%;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 15px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, .9);
    padding-top: 15px;
    position: fixed;
    bottom: 0;
    background-color: white;
    overflow: hidden;
    list-style-type: none;
}

ul.footer__nav__container li a{
    color: gray; 
    text-decoration: none;
    font-size: 20px;
    
    
}

ul.footer__nav__container div li Link:focus{
    color: blue;
}

@media(min-width: 1024px){
    ul.footer__nav__container li a{
        font-size: 30px;
        
    }
}
