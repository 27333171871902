.shop-container {
	margin-bottom: 24px;
	padding: 0;
}

.swiper-container {
	width: 100%;
	height: 100%;
}

.card {
	position: relative;
	background: #ffffff;
	width: 400px;
	height: 500px;
	margin: 0 auto;
	text-align: center;
}

.bolden__font {
	font-weight: bold;
}

.btn__container {
	display: flex;
	justify-content: center;
}

.form__reset__btn {
	background: none;
	border: thin solid rgb(199, 206, 212);
	border-left: none;
	color: gray;
	padding-right: 15px;
	font-weight: bold;

}

.form__reset__btn:focus {
	border: thin solid rgb(199, 206, 212) !important;
	border-right: none !important;

}

.flex__wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}

.trending__cards {
	margin-left: -40%;
}

.trending__img__container img {
	width: 60%;
	margin-left: 0px;
	margin-right: 220px;
	z-index: -1;
	padding-left: 0;
}

.carat__mobile__container {
	/* border: 1px solid red;  */
	width: auto;
	height: 100%;

	background-color: rgb(250, 250, 250);

}

.card .content {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
}

p.card__header {
	font-size: 25px;
	white-space: nowrap;
	font-weight: bold;
}

.swiper__slide a {
	padding: 10px 20px;
	text-decoration: none;
	border: 2px solid #262626;
	color: red;
	font-weight: 600;
}

.card .swiper__text {
	position: relative;
	width: 400px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.best__selling__center__wrapper {
	max-width: 1960px;
	width: 90%;
	margin: 0 auto;
}

.card .swiper__text h3 {
	color: black;
	font-size: 2em;
}

.swiper__slide {
	max-width: 420px;
}

.swiper-container-3d .swiper-slide-shadow-left {
	background-image: none;
}

.swiper-container-3d .swiper-slide-shadow-right {
	background-image: none;
}

.slider .card {
	max-width: 100%;
	flex: 1;
	margin: 0px 10px;
	padding: 0px 30px;
	padding-bottom: 50px;
	text-align: center;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.item {
	position: relative;
	padding-top: 20px;
	display: inline-block;
}

.notify-badge {
	position: absolute;
	left: 20px;
	top: 10px;
	background: #cc7004;
	text-align: center;
	border-radius: 10px 10px 10px 10px;
	color: whitesmoke;
	padding: 3px 5px;
	font-size: 1rem;
}

.top__sellers__img {
	max-width: 100%;
	margin: 0px 10px;
	padding: 0px 30px;
	padding-bottom: 50px;
	text-align: center;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.carats__container {
	display: flex;
}

.carat__img {
	border: 1px solid red;
	width: 100%;
	margin-bottom: 10px;
}

.carat__btn {
	border: none;
	cursor: pointer;
	padding: 0;
}

.modal {
	background: rgba(0, 0, 0, 0.5) !important;
}

.modal-backdrop {
	display: none !important;
}

.trending__carat__img__btn {
	border: none;
	cursor: pointer;
	padding: 0;
	background-color: rgb(250, 250, 250);
}

.screenSize__style {
	width: auto;
	height: 25rem;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 0px !important;

	background-color: white;
}

.category__logo {
	width: 100%;
	height: 70%;
}

.filtered__carats__wrapper {
	max-width: 1960px;
	width: 60%;
	margin: 0 auto;
}

/* @media (max-width: 1024px) {
	.filtered__carats__wrapper{

		width: 80%;

	}
} */

@media (max-width: 1024px) {
	.center__wrapper {
		max-width: 1960px;
		width: 100%;
		margin: 0 25px;
	}

	.filtered__carats__wrapper {
		width: 90%;
	}

	.desktop__nav {
		display: none;
	}
}

@media (max-width: 768px) {
	.card__text {
		font-size: 15px;
		word-wrap: break-word;
	}

	.card__img__top {
		width: 100%;
	}

	.screenSize__style {
		width: auto;
		height: 19rem;
	}

	.category__logo {
		width: 150px;
		height: 150px;
	}

	/* .filtered__carats__wrapper{
		
		width: 100%;
		
	} */
}

@media (max-width: 500px) {
	.trending__header {
		margin-left: 170px;
	}

	.card__text {
		font-size: 3vw;
		width: 100%;
	}

	.card__body {
		padding-left: 0px;
		padding-right: 0px;
	}

	.card__img__top {
		width: 100%;
	}

	.screenSize__style {
		width: 100%;
		height: 12rem;
		padding: 0;
	}

	.category__name {
		font-size: 10px;

	}

	.category__logo {
		width: 54px;
		height: 54px;

	}

	.filtered__carats__wrapper {
		max-width: 1960px;
		width: 100%;
		margin: 0 5px;

	}

	.center__wrapper {
		max-width: 1960px;
		width: 90%;
		margin: 0 auto;

	}

	.trending__cards {
		width: 100%;
		margin-left: -20px;

	}

	.trending__img__container img {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}

	.trending__img {

		height: 100%;
	}

	.trending__cards p {
		font-size: 12px;

	}

	p.card__header {
		font-size: 13px;
		white-space: nowrap;
		font-weight: bold;
	}

	.carat__mobile__container {
		padding: 0px 0;
		background-color: rgb(250, 250, 250);

	}
}