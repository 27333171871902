/* New Styles */



.flex-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 16px;
}

.flex-container p {
    margin: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 12px;
    color: #BBBBBB;

}

.flex-container button {
    border: none;
    background-color: unset;
    color: blue;
    padding: 0;
    margin: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 12px;
}

.form-container {
    margin-bottom: 50px;
}

.form-container form>input, textarea {
    width: 100%;
    margin-bottom: 15px;
    background-color: #F4F4F4;
    border: unset;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 12px;
    height: 35px;
}

.form-container form>textarea {
    height: 130px;
}

.button-container {
    max-width: 1080px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.button-container button {
    margin-bottom: 15px;
}

.editable-label-style {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 17px;
    letter-spacing: -0.177778px;
    color: #424347;
}

.share-modal-style {
    display: flex;
    justify-content: space-between;
}

.modal-header-style {
    margin: 0 auto;
    font-family: Gotham;
}

/* New Styles End Here */

.modal-bottom-position {
    width: 95%;
    top: 80%;
}

.icon__size {
    font-size: 30px;
    padding: 6px;
    align-items: center;
    border: 3px solid black;
    border-radius: 50%;
}