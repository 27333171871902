.editable-label-container {
    margin-bottom: 16px;
}

.label-container {
    display: flex;
}

.label-image {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.label {
    margin-right: 16px;
}

.label-description {
    margin-bottom: 0;
    margin-left: 34px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.6);
}

.label-text {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 18px; 
    color: #191919;
}

.no-label-text {
    @extend .label-text;
    font-style: italic;
    color: #464646;
}
