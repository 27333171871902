.grid-view-container {
    padding: 16px;
}

.title {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.0048px;
    margin-bottom: 24px;
}
