.skip-link-container {
    font-family: Gotham;
    font-style: normal;
    font-weight: 325;
    font-size: 13px;
    line-height: 24px;
    padding: 16px;
    text-align: end;
}

.skip-link-container a {
    color: black;
}

.forgot-password-style {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 15px !important;
    /*Overwrite for Bootstrap Form */
    text-align: end;

    color: #0E6C37;
}

.sign-in-button-format {
    width: 170px;
    height: 30px;
}

.sign-in-button-format p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
}

.link-to-sign-up {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 55px;

    color: rgba(0, 0, 0, 0.5);
}

.link-to-sign-up a {
    color: #0E6C37;
}

.submit__btn__container {
    display: flex;
    justify-content: center;
}

.header-style {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    color: #000000;
    padding-top: 25px;
}

.welcome-sub-header-style {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 19px;
    text-align: center;
    color: #C6C6C6;
    margin-top: 10px;
}


.action-sub-sub-header {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 14px;
    margin-top: 10px;
}

.screen-container {
    display: flex;
    flex-direction: column;
}

.input-border {
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    max-width: 1960px;
    width: 95%;
    height: 34px;
    margin: 0 auto;
    margin-top: 10px;
}

.horizontal-container {
    display: flex;
}

.container-item {
    flex: 1;
    height: 24px;
}

.social-login {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    padding-top: 27px;
    text-align: center;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.5);
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.5);
}

.img__styling {
    max-width: 1960px;
    width: 20%;
    margin: 0 auto;
}



/* Resize Logo based on screen size */
@media (min-width: 1024px) {
    .img__styling {
        max-width: 1400px;
        width: 20px;
        margin: 0 auto;
    }
}

@media (max-width: 500px) {
    .img__styling {
        width: 30%;
        /* from Figma */
        margin: 0 auto;
    }
}