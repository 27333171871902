

div.continue__btn__container button {
    width: 80%;
    margin-bottom: 50px;
    padding: 10px;
}

div.order__details__btn__container button {
    width: 80%;
    padding: 10px;
}

.orderCompleted__img__format {
    width: 70%;
    margin-bottom: 10px;
}

.img-container {
    padding: 0 0 15px 0;
}

.buttons-container {
    text-align: center;
    margin-top: 50px;
}

.accordion-header {
    margin-bottom: 0;
    font-family: Gotham;
}

.order-summary-container {
    padding: 0;
}

.order-completed-heading {
    font-family: Gotham;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 21.05px;
    letter-spacing: -0.55px;
    background-image: linear-gradient(130.55deg, #7488F1 26.95%, #2C5A86 105.8%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}


.sub-list-container {
    display: flex; 
    justify-content: space-between;
    font-family: Gotham;
}

.list-container {
    margin-top: 20px;
    /* padding-left: 30px; */
    padding-right: 20px;
}

.image-and-confirmation-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.carat-list-price {
    float: right;
}

.total-amount {
    float: right;
    margin-top: 30px;
    font-weight: bold;
}

div.message-container {
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
}

p.message-alignment {
    text-align: center;
    margin: 10px;

}