.profile-image {
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
}

.centered-div {
	position: relative; 
}

.img-upload-camera-icon {
	position: absolute;
	right: 0;
	bottom: 0;
}

.upload__img__format {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.upload__img__format1{
	position: relative;
}

.page__wrap {
	min-height: 100%;
	margin-bottom: auto;
}

.page__wrap:after {
	content: "";
	display: block;
}

.desktop__footer,
.page__wrap:after {
	height: 162px;
}

.desktop__footer {
	background: black;
}

.upload__img__container {
	width: 20vw;
	height: 10vh;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 50%;
}

.add__photo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-weight: bold;
	font-size: 20px;
	white-space: nowrap;
}
