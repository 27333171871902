.purchased__img__format{
    width: 80%;
    
}

.purchased__send__btn{
    width: 50%;
    padding: 7px;
    
}

@media (max-width: 320px){
    .purchased__send__btn {
        width: 100%;
    }
}

@media (max-width: 375px){
    .purchased__send__btn {
        width: 100%;
    }
}


@media (max-width: 1024px) {
    .purchased__send__btn {
        width: 80%;
    }
}





