.center {
    margin-top: 10px;
    position: relative;
    height: 50vh;
    transform: translate(0px, 25%);
    padding: 10px;
    margin-left: 70px;
}

.container {
    padding: 16px;

}

.screen {
    height: 100vh;
}

.header-font {
    font-family: Aileron;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.55px;
    color: #79ADD5;
    white-space: nowrap;

}


.app-icon-position-style {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 64px;
    height: 68px;
}

.learn-more-text-style {
    margin-top: 20px;
    font-family: Gotham;
    text-align: center;

}


.splash-image-style {
    max-width: 1960px;
    width: 100vw;
    height: 75vh;

}

.splash-container {
    display: flex;
    flex-direction: column;

    /* Overwrite of global container padding */
    margin: 20px -10px 0px -20px;
    margin-bottom: 25px;

}

.splash-header-container {
    display: flex;
}

.splashscreen-button-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.splashscreen-button-container img {
    border-radius: 5px;
}

.splashscreen-button-container p {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.55px;
    color: #FFFFFF;
}


.splashscreen-button-container {
    display: flex;
    justify-content: center;
}

@media (max-width: 375px) {
    .splash-image-style {
        height: 60vh;
    }
}