
/* Global Styles */
h1,
h2, 
h3, 
h4 {
    margin:0;
    padding: 0;
}

.cart__page__wrap{
    min-height: 100%;
    
    margin-bottom: auto;
}

.cart__page__wrap:after {
    content: "";
    display: block;
}

.cart__desktop__footer{
    height:162px;
}
.cart__page__wrap:after {
    height: 280px;
}

.cart__desktop__footer{
    background: black;
}

.img__format img {
    border: 1px solid black !important;
    width: 100%;
    height: 110%;
    border-radius: 1vw;
}

.button__container{
    padding-bottom: 30%;
}


.qty__counter__container{
    margin-top: 15px;
    padding: 0;
}

.increase__btn{
    font-size: 20px;
    border: none;
    width: 25%;
    background-color: rgb(233, 232, 232);
}


.qty__label{
    text-align: left;
    width: 25%;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 2.5px;
    border: 0;
    background-color: rgb(233, 232, 232);
    
}


.decrease__btn{
    border: none;
    width: 25%;
    font-size: 20px;
    padding-left: 10px;
    margin-left: 0px;
    background-color: rgb(233, 232, 232);

}
.remove__all{
    border: none;
    width: 23%;
    margin-left: 3px;
    font-size: 20px;
    margin-right: 0;
    background-color: rgb(233, 232, 232);
    /* border-left: 5px solid white; */
    
    padding: 0;
}



@media (max-width: 1024px) {
    .cart__desktop__footer{
        display: none;
    }
}

@media (max-width: 768px) {
    .pull__left{
        margin-left: -20px;
    }
}

@media (max-width: 500px){
     .button__container button{
        width: 100%;
    }
     
     .qty__label{
         padding-bottom: 1px;
         text-align: center;
     }
     
     .qty__counter__container{
         margin-top: 0;
         width: 120% !important;
         margin-right: 20px;
     }
     .increase__btn{
         border-top-right-radius: 5px;
         border-bottom-right-radius: 5px;
     }
     
     .decrease__btn{
         border-top-left-radius: 5px;
         border-bottom-left-radius: 5px;
     }
     .remove__all{
         margin-left: 0px;
         border:3px solid white;
         border-radius: 7px;
     }
     .img__format img{
         width: 105%;
         margin-left: 20px;
         padding-left: 0;
     }
    
}




