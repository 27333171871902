.container {
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
}

$max-cc-thumbnail-height: 120px;
$max-coupon-thumbnail-height: 72px;
$max-coupon-thumbnail-width: 88px;

.img-container {
    max-height: $max-cc-thumbnail-height;
    height: auto;
}

img.coupon-img-thumbnail-container {
    height: $max-coupon-thumbnail-height;
    width: $max-coupon-thumbnail-width;
}

.heading {
    font-family: Gotham;
    font-style: normal;
    line-height: 18px;
    color: #000000;
}

.non-swipeable-checkout-card {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.0048px;
}

.non-swipeable-card {
    /* non-swipeable-card - in shop detail */
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.0048px;
}

.swipeable-card {
    /* swipeable card - in shop home */
    font-weight: 350;
    font-size: 18px;
}

.sub-heading {
    font-family: Gotham;
    font-style: normal;
    font-weight: 350;
    font-size: 13px;
    line-height: 18px;
    color: #4a4a4a;
}

.text-overlay {
    /* Position in div */
    position: absolute;
    left: 24px;
    top: 8px;

    /* Background */
    background: #8c9ebc;
    backdrop-filter: blur(27.1828px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 3px;

    /* Font */
    font-family: Gotham;
    font-style: normal;
    font-weight: 350;
    font-size: 15px;
    line-height: 11px;
    text-align: center;
    color: #ffffff;
    padding: 4px;
}

.primary-caption-font {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.00252px;
}

.secondary-caption-font {
    font-family: "Gotham";
    font-style: normal;
    font-weight: bold;
    font-size: 12.5px;
    line-height: 11px;
    letter-spacing: 0.022px;
    color: #4a4a4a;
    word-wrap: break-word;
    width: 85%;
}
