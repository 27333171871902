/* updated styles */
$max-coupon-thumbnail-width: 72px;
$max-coupon-thumbnail-height: 88px;

.coupon-and-trash-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.coupon-and-trash-container img {
	max-height: $max-coupon-thumbnail-width;
	max-width: $max-coupon-thumbnail-height;
	position: relative;
}

.coupon-and-trash-container div img {
	position: absolute;
	bottom: -20px;
	left: 2.5em;
	// width: 15%;
}

.btn-alignment {
	
}

.heading-style {
	font-family: Gotham;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 15px;
	color: #3e3e3e;
	margin: 16px 0;
}

.swiper-wrapper {
	padding-bottom: 32px;
	z-index: 0;
}

.swiper-container {
	z-index: 0;
}

.margin-top-override {
	margin-top: -20px;
	position: relative; 
}

.discount-message-style {
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 14px;
	text-transform: capitalize;
	color: #424347;
}

.bottom-positioning {
	position: absolute; 
	bottom: 0;
	width: 90%;
}
