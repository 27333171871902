.swiper-heading {
    display: flex;
    justify-content: space-between;
}

.swiper-title {
    margin-bottom: 16px;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 18px;
    letter-spacing: 0.0042px;
    color: #000000;
}

.swiper-subtitle {
    font-family: Gotham;
    font-style: normal;
    font-weight: 325;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.002px;
    color: #9B9B9B;
}

.swiper-wrapper {
    padding-bottom: 32px;
    z-index: 0;
}

.swiper-container {
    z-index: 0;
}

.link-style {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    letter-spacing: 0.002px;
    color: #1C7543;


}