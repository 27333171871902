.searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

input {
  position: relative;
  width: 100%;
  height: 48px;
  text-indent: 16px;
  border: 0px;
  background: #FBFBFB;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
}

.rightAlignedIcon {
  position: absolute;
  width: 16px;
  color: black;
  right: 36px;
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding-left: 16px;
}