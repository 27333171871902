@import "../../../App.scss";

.icon-small {
    width: 36px;
}


/***********************************************/

.body {
    margin: 0;
    font-family: Helvetica, sans-serif;
    margin-bottom: 80px;
}

a {
    color: #000;
}


/* header */

header {
    position: fixed;
    top: 0;
}

.nav-container {
    display: flex;
    justify-content: center;
}

.page-title {
    font-size: 20px;
    margin-bottom: 0; // override h1
}

.stay-inside {
	position: relative;
    width: 100%;
}

.horizontal-center {
    display: flex;
    justify-content: center;
}

.back-button {
    position: absolute;
    left: 0;
}


/******** This is broken ***************/

.header {
    background-color: #fff;
    height: 0px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
    position: fixed;
    width: 100%;
    z-index: 3;
}

.pageTitle {
    /* position: relative; */
    display: flex;
    justify-content: center;
    width: 20%;
    padding-top: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
    font-size: 25px;
    text-decoration: none;
    background-color: white;
    display: none;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}

.header ul {
    list-style-type: none;
}

.header li a {
    display: inline-block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}

.header .logo {
    float: left;
    font-size: 17px;
    padding: 20px 0px;
    text-decoration: none;
    background-color: white;
    width: calc(100vw - 619.7px);
}

.go__back__btn {
    display: none;
    float: left;
    font-size: 2em;
    padding: 20px 20px;
    position: fixed;
    margin: 0;
    text-decoration: none;
    z-index: 3;
}


/* menu */

.header .menu {
    clear: both;
    max-height: 0px;
    transition: max-height .2s ease-out;
}


/* menu icon */

.header .menu-icon {
    cursor: pointer;
    float: right;
    /* padding: 20px 20px; */
    user-select: none;
}

.header .menu-icon .nav-icon {
    background: #333;
    display: block;
    height: 3px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .nav-icon:before,
.header .menu-icon .nav-icon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .nav-icon:before {
    top: 7px;
}

.header .menu-icon .nav-icon:after {
    top: -7px;
}


/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked~.menu {
    max-height: 340px;
}

.header .menu-btn:checked~.menu-icon .nav-icon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
}

.header .menu-btn:checked~.menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
}


