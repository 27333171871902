.expiry-and-cvc-container {
    display: flex;
}

.add-card-button-style {
    background: linear-gradient(92.8deg, #8FC4E8 -6.07%, #AFA7E1 113.21%);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    padding: 8px 80px;
}

.footer-container {
    display: flex; 
    justify-content: center;
}

