.sub-heading {
    margin-top: 8px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    color: #BBBBBB;
}

.sub-sub-heading {
    margin-top: 8px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: -0.155556px;
}