@font-face {
    font-family: "Gotham";
    src: url("./fonts/Gotham-Book/Gotham\ Book.otf");
}

@font-face {
    font-family: "Aileron";
    src: url("./fonts/Aileron/Aileron-Black.otf");
}

.top {
    padding: 20px;
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: white;
}

.middle {
    padding: 20px;
    z-index: 0;
    height: 100vh;
}

.bottom {
    z-index: 1;
    position: sticky;
    bottom: 0;
}

.editable-modal-input {
    margin: 16px 0;
    width: 100%;  
}


