.brand__logo {
    /* width: 84px; */
    height: 68px;
    border-radius: 10px;
}

.brand__container {
    height: 100%;
    /* padding-top: 3px; */
}

.upload__media__container {
    width: 50%;
    height: 7vh;
    position: relative;
    top: 0;
    right: 0;
    border-radius: 20%;
    background-color: lightgray;
}

.upload__media__format {
    width: 100%;
    height: 7vh;
    position: absolute;
    border-radius: 20%;
    /* background-color:lightgray; */
}

.coupon__container {
    height: 25px;
    border-radius: 20%;
    background-color: lightgray;
    margin: 0;

}

/* Change this, it is hard coded */
.coupon__swiper {
    width: 250px;
}

.plus__icon {
    font-size: 30px;
    padding-top: 0px;
}

.coupon__swiper .swiper-slide {
    text-align: center;
    height: 100px;
    border-radius: 20px;
    width: 90px !important;
}

.title-styling {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    color: #3E3E3E;
    margin-top: 16px;
}

.upload-container {
    display: flex;
}

.margin-right {
    margin-right: 16px;
}

.vault-modal-header-style {
    position: absolute;
    width: 50px;
    height: 24px;
    left: 162px;
    top: 30px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.55px;
    border-bottom: none;
}

.modal {
        padding-top: 30vh;
}

/* Remove scroll-bar for different browsers */

.horizontal-scroll {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    white-space: nowrap; 
}

.horizontal-scroll::-webkit-scrollbar { /* WebKit */
    width: 90%;
}

.horizontal-scroll-spacing {
    display: inline-block; 
    margin-right: 1.5em;
}

.nft-checkbox {
    margin-top: 20px;
}
.nft-checkbox label{
    margin-left: 10px;
}